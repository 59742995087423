<template>
  <div class="navapp">
    <div class="navapp__wrap my-5 pt-5 is-flex is-flex-direction-column  is-align-items-center">

        <!--- content -->
        <div v-for="item in navlist" :key="item.id">
          <router-link v-if="can(item.permission)" :to="item.slug" class="button my-2" :title="item.name" >
            <unicon :name="item.icon" fill="#242424" width="18" height="18" />
          </router-link>
        </div>

         <!--- content -->
    </div>

    <div class="navapp__settings">
      <a href="#" class="button is-rounded logout is-light" @click.prevent="handleLogout" title="Logout">
        <unicon name="sign-out-alt" fill="#f14668" />
      </a>
    </div>

  </div>
</template>

<script>
import { ref } from 'vue'
import { useMutation } from "@vue/apollo-composable";
import {LOGOUT_MUTATION} from "@/graphql/mutations/authMutation";
import router from "@/router";
import useAuth from "@/use/useAuth";
import { useUser } from "@/use/useUser";

export default {
  name: "NavApp",
  components: {

  },
  setup () {

    const navlist = ref([
      { id: 0, name: 'Dashboard', slug: '/', icon: 'apps', permission: 'dashboard-view' },
      { id: 1, name: 'Projects', slug: '/projects', icon: 'folder',  permission: 'project-view' },
      // { id: 2, name: 'Tasks', slug: '/tasks', icon: 'list-ul',  permission: 'project-view' },
      // { id: 2, name: 'Team', slug: '/team', icon: 'users-alt',  permission: 'team-view' },
      // { id: 3, name: 'Clients', slug: '/clients', icon: 'bag',  permission: 'client-view' },
      // { id: 4, name: 'Report', slug: '/reports', icon: 'chart',  permission: 'report-view' },
    ]);

    const { clearToken, setAuthenticated } = useAuth()

    const { clearCurrentUser, can } = useUser()

    const {mutate: logout, error, onDone} = useMutation(
        LOGOUT_MUTATION,
        () => ({
          clientId: 'cmsClient',
        }));

    const handleLogout = () => {
      logout()

      onDone(({data}) => {
        clearToken()
        clearCurrentUser()
        setAuthenticated()
        router.push('/login')
      })
    }


    return {
      handleLogout,
      navlist,
      can
    }
  }
}
</script>


<style lang="scss">
  .navapp {
    width: 60px;
    height: 100vh;
    position: fixed;
    left: 0;
    top:0;
    z-index: 10;
    overflow: hidden;
    transform: translate(-100%,0);
    transition: all 800ms cubic-bezier(0.075, 0.82, 0.165, 1);
    padding: 80px 0;
    box-sizing: border-box;


    &.active {
      transform: none;
    }

    &__wrap {
      min-height: 100%;

      .unicon {
        line-height: 1;
      }

      .button {
        min-height: 40px;
        background: transparent;
        border-color: transparent;
        outline: 0 none;
        border-radius: 100%;
        padding: 10px;
        margin: 10px 0;

        &.router-link-exact-active,
        &.router-link-active,
        &:hover,
        &:focus,
        &:active {
          /* background: royalblue; */
          background: #fff;
          border-color: transparent;
          outline: 0 none;
          box-shadow: none;

          .unicon svg {
            fill: hsl(171, 100%, 41%)	;
          }
        }
      }
    }

    &__settings {
      position: absolute;
      bottom: 30px;
      width: 100%;
      display: flex;
      justify-content: center;

      .logout {
        margin: 0 auto;
        width: 42px;
        min-height: 42px;
      }
    }

  }


  @media screen and (min-width: 992px) {
    .navapp {
      transform: translate(0,0);
      padding-top: 200px;
      width: 80px;
    }
  }
</style>
